.latestBlogtitle {
  margin-top: 50%;
  color: #092a41;
  font-size: 46px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 700px) {
    margin-top: 220% !important;
  }

  @media screen and (min-device-width: 1400px) and (max-device-width: 2000px) { 
    margin-top: 60% !important;
}
}

.blogListtitle {
  margin-top: 200px;
  margin-bottom: 20px;
  color: #092a41;
  font-size: 46px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 700px) {
    margin-top: 66px;
    margin-bottom: 20px;
    color: #092a41;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
  }
}

.content {
  text-align: center;
  /* display: grid; */
  color: #02090e;
  font-size: 20px;
  font-weight: 400;
  padding: 30px;
  @media (max-width: 700px) {
    text-align: justify;
  }
}

.blogTitle {
  font-size: 64px;
  color: white;
  font-size: 64px;
  color: white;
  font: bold 200% Consolas, Monaco, monospace;
  border-right: .1em solid black;
  width: 13.20ch;
  margin: 2em 2em;
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: typing 5s steps(13, end),
    blink-caret .5s step-end infinite alternate;
}

.imageBlog {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 643px !important;
  width: 100%;
}

.blogList {
  grid-row: 3 !important;
}

.showButton {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all .15s ease;
  width: 200px;
  align-items: center;
  top: 150px;

  @media (max-width: 700px) {
    top: 0px;
    margin-top: 422px;
  }

}

.showButton::before,
.showButton::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7D8082;
  transition: all .15s ease;
}

.showButton::before {
  top: 0;
  border-bottom-width: 0;
}

.showButton::after {
  bottom: 0;
  border-top-width: 0;
}

.showButton:active,
.showButton:focus {
  outline: none;
}

.showButton:active::before,
.showButton:active::after {
  right: 3px;
  left: 3px;
}

.showButton:active::before {
  top: 3px;
}

.showButton:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.button_lg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all .2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #ff4655;
  transform: skew(-15deg);
  transition: all .2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.showButton:hover .button_sl {
  width: calc(100% + 15px);
}

.showButton:hover .button_lg::after {
  background-color: #fff;
}

.parentCardBlog {
  display: flex;
  flex-flow: wrap;
}


.cardBlog {
  position: relative;
  width: 380px;
  /* height: 250px; */
  overflow: hidden;
  margin: 33px;

  transition: 0.5s;
}

.cardBlog:hover {
  transform: translateY(-20px);
}

.blogimg {
  width: 450px;
  height: 250px;
  overflow: hidden;
  background-color: transparent;
  background: transparent;
  border-radius: 4px;
  object-fit: cover;

}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}


.rightBlog {
  right: 0;
  /* background: rgb(22,22,22);
background: linear-gradient(90deg, rgba(22,22,22,1) 0%, rgba(133,106,89,1) 78%); */
  /* background-color: rgb(169, 144, 120); */
  /* background: rgb(216,192,168); */
  /* background:rgb(74,88,79); */
  background-color: black;
  /* background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%); */
}


.projcard-container {
  margin: 50px 0;
}

/* Actual Code: */
.projcard-container,
.projcard-container * {
  box-sizing: border-box;
}

.projcard-container {
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.projcard {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #ddd;
  font-size: 18px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);
  transition: box-shadow 0.2s ease, transform 0.2s ease;

}

.projcard:hover {
  box-shadow: 0 34px 32px -33px rgba(0, 0, 0, .18);
  transform: translate(0px, -3px);
}

.projcard::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 0.07;
}

.projcard:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-innerbox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.projcard-img {
  position: absolute;
  height: 300px;
  width: 400px;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
}

.projcard:nth-child(2n) .projcard-img {
  left: initial;
  right: 0;
}

.projcard:hover .projcard-img {
  transform: scale(1.05) rotate(1deg);
}

.projcard:hover .projcard-bar {
  width: 70px;
}

.projcard-textbox {
  position: absolute;
  top: 7%;
  bottom: 7%;
  left: 430px;
  width: calc(100% - 470px);
  font-size: 17px;
}

.projcard:nth-child(2n) .projcard-textbox {
  left: initial;
  right: 430px;
}

.projcard-textbox::before,
.projcard-textbox::after {
  content: "";
  position: absolute;
  display: block;
  background: #ff0000bb;
  background: #fff;
  top: -20%;
  left: -55px;
  height: 140%;
  width: 60px;
  transform: rotate(8deg);
}

.projcard:nth-child(2n) .projcard-textbox::before {
  display: none;
}

.projcard-textbox::after {
  display: none;
  left: initial;
  right: -55px;
}

.projcard:nth-child(2n) .projcard-textbox::after {
  display: block;
}

.projcard-textbox * {
  position: relative;
}

.projcard-title {
  font-family: 'Voces', 'Open Sans', arial, sans-serif;
  font-size: 24px;
}

.projcard-subtitle {
  font-family: 'Voces', 'Open Sans', arial, sans-serif;
  color: #888;
}

.projcard-bar {
  left: -2px;
  width: 50px;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.2s ease;
}

.projcard-blue .projcard-bar {
  background-color: #5f5756;
}

.projcard-blue::before {
  background-image: linear-gradient(-70deg, #ff0000ad, transparent 50%);
}

.projcard-blue:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #0088FF, transparent 50%);
}

.projcard-description {
  z-index: 10;
  font-size: 15px;
  color: #424242;
  height: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projcard-tagbox {
  position: absolute;
  bottom: 3%;
  font-size: 14px;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.projcard-tag {
  display: inline-block;
  background: #E0E0E0;
  color: #777;
  border-radius: 3px 0 0 3px;
  line-height: 26px;
  padding: 0 10px 0 23px;
  position: relative;
  margin-right: 20px;
  cursor: default;
  user-select: none;
  transition: color 0.2s;
}

.projcard-tag::before {
  content: '';
  position: absolute;
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  height: 6px;
  left: 10px;
  width: 6px;
  top: 10px;
}

.projcard-tag::after {
  content: '';
  position: absolute;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #E0E0E0;
  border-top: 13px solid transparent;
  right: -10px;
  top: 0;
}

.quotes {
  width: 42px;
  left: -50%;
}

.quotesend {
  width: 42px;
  right: -50%;
}

.readmore-btn {
  width: fit-contentd;
  height: 55px;
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(7 11 37);
  margin-top: 18px;
  border: none;
  border-radius: 10px;
  padding: 0px 15px;
  gap: 0px;
  transition: all 0.4s;
}

.book-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 45px;
  height: 100%;
}

.book-wrapper .book-page {
  width: 50%;
  height: auto;
  position: absolute;
}

.readmore-btn:hover .book-page {
  animation: paging 0.4s linear infinite;
  transform-origin: left;
}

.readmore-btn:hover {
  background-color: rgb(159, 74, 255);
}

@keyframes paging {
  0% {
    transform: rotateY(0deg) skewY(0deg);
  }

  50% {
    transform: rotateY(90deg) skewY(-20deg);
  }

  100% {
    transform: rotateY(180deg) skewY(0deg);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}

.text {
  width: 105px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.blogcardmobile-container {
    width: 400px;
    height: 400px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 20px;
}

.blogcardmobile {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.blogcardmobile .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.blogcardmobile .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, #000000 0%, #38393b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.blogcardmobile .contentmobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(100%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  @media (max-width: 700px) {
    text-align: justify;
  }
}


.blogcardmobile .contentmobile.blogHeading {
  font-size: 32px;
  font-weight: 700;
}

.blogcardmobile:hover .contentmobile {
  transform: translateY(0);
  width: auto;
  }


.blogcardmobile:hover .front-content {
  transform: translateY(-30%);
  }


.blogcardmobile:hover .front-content p {
  opacity: 0;
  }


.readmoreButton {
  padding: 15px 20px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  z-index: 1;
  }


.readmoreButton::after,
.readmoreButton::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
  }


.readmoreButton::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
  }


.readmoreButton::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.readmoreButton:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
  }


.readmoreButton:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  }


.readmoreButton:active::after {
  transition: 0s;
  transform: translate(0, 5%);
  }
