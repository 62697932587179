.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;

  @media (max-width: 700px) {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    --size: 40px;
    /* control the size */
    --color: #d1bdbd;

    background: radial-gradient(circle at 15% 30%,
        transparent 40%,
        var(--color) 41%),
      radial-gradient(circle at 85% 30%, transparent 40%, var(--color) 41%),
      radial-gradient(circle at 50% 70%, transparent 40%, var(--color) 41%),
      radial-gradient(circle at 15% 70%, transparent 40%, var(--color) 41%),
      radial-gradient(circle at 85% 70%, transparent 40%, var(--color) 41%),
      linear-gradient(45deg,
        var(--color) 25%,
        rgba(0, 0, 0, 0.067) 0,
        rgba(0, 0, 0, 0.067) 50%,
        var(--color) 0,
        var(--color) 75%,
        #1111 0,
        #1111 100%,
        var(--color) 0);
    background-size: var(--size) var(--size);
  }
}

/* Control the left side */
.left {
  left: 0;
  background-color: #111;
  object-fit: cover;
  @media (max-width: 700px) {
    display: none;
  }
}

/* Control the right side */
.right {
  right: 0;
  /* background-color: #F4FDFF; */
  --size: 40px;
  /* control the size */
  --color: #d1bdbd;

  background: radial-gradient(circle at 15% 30%,
      transparent 40%,
      var(--color) 41%),
    radial-gradient(circle at 85% 30%, transparent 40%, var(--color) 41%),
    radial-gradient(circle at 50% 70%, transparent 40%, var(--color) 41%),
    radial-gradient(circle at 15% 70%, transparent 40%, var(--color) 41%),
    radial-gradient(circle at 85% 70%, transparent 40%, var(--color) 41%),
    linear-gradient(45deg,
      var(--color) 25%,
      rgba(0, 0, 0, 0.067) 0,
      rgba(0, 0, 0, 0.067) 50%,
      var(--color) 0,
      var(--color) 75%,
      #1111 0,
      #1111 100%,
      var(--color) 0);
  background-size: var(--size) var(--size);
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


.homeTitle {

  font-size: 72px;
  /* font-family: 'Sora',sans-serif 'cursive'; */
  /* font-family: "Allura", cursive; */
  /* font-family: "Lucida Handwriting"; */
  font-family: 'Brush Script MT', 'cursive';
  font-style: italic;

  @media (max-width: 700px) {
    font-size: 48px;
  }
}

.homesubTitle {
  text-align: right;

  font-family: "Lucida Handwriting";
  font-style: italic;
}

.homedes {
  margin-top: 15px;
  font-size: 20px;
  text-align: left;
  color: #252222;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  margin-top: 30px;
  width: 12rem;
  height: auto;
  text-align: left;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}

.cta {
  display: block;
  margin-top: 20px;
  border: none;
  background: none;
  font-size: 14px;
  font-family: 'Sora', sans-serif
}

.cta span {
  padding-bottom: 7px;
  font-size: 14px;
  padding-right: 15px;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.homedesFooter {
  font-size: 16px;
  margin-top: 90px;
  text-align: left;
  font-family: 'Sora', sans-serif;
  font-weight: 100;
}

.cardspotify {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  box-shadow: 0px 0px 20px #d1bdbd;
  margin-top: 20px;
}

/* for all social containers*/
.socialContainer {
  width: 52px;
  height: 52px;
  background-color: rgb(83, 77, 77);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
  background-color: #1ED760;
  transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
  background-color: #B150E2;
  transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {

  background: linear-gradient(to right, #0057e7, #d62d20, #ffa700, #008744, #0057e7);
  transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
  background-color: #128C7E;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 24px;
}

.socialSvgApple {
  width: 42px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialSvgApple path {
  fill: rgb(255, 255, 255);
}

.socialSvgAmazon {
  width: 22px;
}

.socialSvgAmazon path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvgAmazon {
  animation: slide-in-top 0.3s both;
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

.socialContainer:hover .socialSvgApple {
  animation: slide-in-top 0.3s both;
}

.containerFour:hover {

  background-color: #00A8E1;

  transition-duration: .3s;

}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}