.footer {
  font-size: 40px;
  bottom: 0 !important;
  position: inherit;
  width: 100%;
  display: flex;
}

.footeralign {
  display: flex;
}

.container {
  width: 207px;
  height: 150px;
  background: #F8F9FD;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
  margin-right: 40px;
  overflow: hidden;
  display: inline-block;

  @media (max-width: 700px) {
    width: 263px;
    height: 80px;
    padding: 2px;
  }

}

.heading {
  text-align: center;
  font-size: 24px;
  color: #0f1923;
  margin-top: 0px;
  @media (max-width: 700px) {
    font-size: 18px;
    line-height: 13px;
  }
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
  @media (max-width: 700px) {
    display: none;
  }
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 9px;
}

.parent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
  border-radius: 50%;
  margin: 0 5px;
}

.child:hover {
  background-color: white;
  background-position: -100px 100px, -100px 100px;
  transform: rotate3d(0.5, 1, 0, 30deg);
  transform: perspective(180px) rotateX(60deg) translateY(2px);
  box-shadow: 0px 10px 10px rgb(1, 49, 182);
}

button {
  border: none;
  background-color: transparent;
  font-size: 20px;
}

.button:hover {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0px, 0px, 15px) perspective(180px) rotateX(-35deg) translateY(2px);
  border-radius: 50%;
}

.modalPopUpButton {
  text-transform: none !important;
  color: white !important;
}

.logo {
  font-family: "Work Sans, sans-serif";
  font-weight: 600;
  color: #FFFF !important;
  text-transform: none !important;
}

.wrapper {
  position: relative;
  --white: #e3e3e3;
  --bc: rgb(203,46,79);
  --bc-al: rgba(203,46,79,0.6);
}

.cardModalmobile {
  max-width: 340px;
  padding: 1rem 0;
  display: grid;
  grid-template-areas: "top" "bottom";
  grid-template-rows: 20% 1fr;
  border: 1px solid var(--bc-al);
  border-radius: .75rem;
  background: var(--bc-al);
  color: var(--white);
  backdrop-filter: blur(14px);
  box-shadow: 0 15px 30px -15px var(--bc-al);
  isolation: isolate;
  height: 509px;
}

.circle-1 {
  position: absolute;
  content: '';
  width: 5rem;
  height: 5rem;
  left: 1rem;
  top: 50%;
  transform: translate(-50%);
  background-color: var(--bc-al);
  border-radius: 50%;
  z-index: -10;
}

.circle-2 {
  position: absolute;
  content: '';
  width: 3rem;
  height: 3rem;
  right: -1rem;
  top: 3rem;
  background-color: var(--bc-al);
  border-radius: 50%;
  z-index: -10;
}

.top {
  padding: .5rem 4rem;
  z-index: 1;
}

.bottom {
  z-index: 2;
}

.users a {
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
}

.user {
  color: white;
  padding: .75rem 4rem;
  display: flex;
  gap: .1rem;
  flex-direction: column;
  align-items: flex;
  font-size: 1.1rem;
  border-bottom: 1px solid var(--bc-al);
  position: relative;
}

.user:hover {
  background-color: var(--bc-al);
}

.user:nth-last-child(1) {
  border-bottom: none;
}

.user::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  left: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  border-radius: 50%;
}

.user-name {
  font-weight: 700;
  font-style: italic;
}

.user-occupation {
  opacity: .8;
}

.u-l {
  font-size: 1.1rem;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}